@import "./src/scss/_modules/variables";
@import "./node_modules/bootstrap/scss/bootstrap";
/* #GLOBAL IMPORTS
========================================================================== */
@import '_imports/_global-import';

/* #WEBFONT ICONS IMPORTS
========================================================================== */
@import '../custom/webfonts/fontawesome-pro-master/scss/fontawesome.scss';
@import '../custom/webfonts/fontawesome-pro-master/scss/fa-light.scss';
@import '../custom/webfonts/fontawesome-pro-master/scss/fa-solid.scss';
@import '../custom/webfonts/nextgen-fonts-master/scss/ng-icons.scss';